.ContainerGeneral.scroll{
    overflow: auto;
    overflow-x: hidden;
   
}
.ContainerGeneral::-webkit-scrollbar {
    width: 10px; 
  
}


.ContainerGeneral::-webkit-scrollbar-thumb {
    background: rgba(231, 19, 131, 0.4);
    border-radius: 2px;
    border-right: none;
    border-left: none;
    max-height: 90x;
}

.ContainerGeneral::-webkit-scrollbar-thumb:active {
    background-color: rgba(231, 19, 131, 0.4);
}

.ContainerGeneral::-webkit-scrollbar-thumb:hover {
    background: rgba(231, 19, 131, 0.4);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.ContainerGeneral::-webkit-scrollbar-track {
    border: 1px solid rgba(231, 19, 132, 0.235);
    border-radius: 2px;
    display: flex;
   
}

