.scroll{
    overflow: auto;
    overflow-x: hidden;
}

.scroll::-webkit-scrollbar {
    width: 8px;
}

.scroll::-webkit-scrollbar-thumb {
    background: #E71383;
}

.scroll::-webkit-scrollbar-thumb:active {
    background-color: #E71383;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background: #E71383;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.scroll::-webkit-scrollbar-track {
    border: 1px solid #E71383;
}

.card-commander{
    border: 1.33091px solid #E71383;
}

.card-commander:hover{
    border: 2px solid #13cbe7;
}

.btn-select{
    cursor: pointer;
}
