
.table-main{
    width: 100%;
    color: white;
    padding: 20px;
    border-collapse: collapse;
}


.selected{
  filter: drop-shadow(1px 1px 2px rgba(0, 0, 0, 0.25)) drop-shadow(1px 1px 10px rgba(20, 219, 245, 0.9)) !important;
}

.show-div {
    display: block !important;
}
  
.hide-div {
    display: none !important;
}

.slick-current:active{
    color: aqua;
}

.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    width: 50%;
    padding: 5px;
  }
  .popup-arrow {
    color: rgb(255, 255, 255);
  }
  [role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
  }
  
  .popup-overlay {
    background: rgba(0, 0, 0, 0.5);
  }
  [data-popup='tooltip'].popup-overlay {
    background: transparent;
  }

  .line{
    background-color: aqua;
    width: 100%;
    transition: width 0.35s;
  }

  .line:hover{
    background-color: aqua;
    width: 10%;
  }

  .custom-dropdown .dropdown-content {
    background-color: rgb(0, 0, 0);
    border: 1px solid #ff0000;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
  }

