.scroll.scroll{
    overflow: auto;
    overflow-x: hidden;
}

.color-red{
    background-color: red;
}

.scroll::-webkit-scrollbar {
    width: 8px;
}

.scroll::-webkit-scrollbar-thumb {
    background: #E71383;
}

.scroll::-webkit-scrollbar-thumb:active {
    background-color: #E71383;
}

.scroll::-webkit-scrollbar-thumb:hover {
    background: #E71383;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.scroll::-webkit-scrollbar-track {
    border: 1px solid #E71383;
}

.scroll2.scroll{
    overflow: scroll;
    overflow-x: hidden;
}

.scroll2::-webkit-scrollbar {
    width: 10px; 
}

.scroll2::-webkit-scrollbar-thumb {
    background: rgba(231, 19, 131, 0.4);
    border-radius: 2px;
    border-right: none;
    border-left: none;
    max-height: 90x;
}

.scroll2::-webkit-scrollbar-thumb:active {
    background-color: rgba(231, 19, 131, 0.4);
}

.scroll2::-webkit-scrollbar-thumb:hover {
    background: rgba(231, 19, 131, 0.4);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);
}

.scroll2::-webkit-scrollbar-track {
    border: 1px solid rgba(231, 19, 132, 0.235);
    border-radius: 2px;
    display: flex;
}

.scroll3::-webkit-scrollbar {
    width: 4px; 
}

.scroll3::-webkit-scrollbar-thumb {
    border: 1.33px solid #6C11DE;
    background-color: #2E084A;
    border-radius: 3px;
}

.scroll3::-webkit-scrollbar-thumb:active {
    border: 1.33px solid #6C11DE; 
}

.scroll3::-webkit-scrollbar-thumb:hover {
    border: 1.33px solid #6C11DE;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);  
}

.scroll3::-webkit-scrollbar-track {
    border: 1px solid rgba(128, 128, 128, 0.119);
    margin-bottom: 15px;
    border-radius: 2px;
}

.scroll4::-webkit-scrollbar {
    width: 4px; 
}

.scroll4::-webkit-scrollbar-thumb {
    border: 1.33px solid rgba(231, 121, 19, 1);
    background-color: #2E084A;
    border-radius: 3px;
}

.scroll4::-webkit-scrollbar-thumb:active {
    border: 1.33px solid rgba(231, 121, 19, 1); 
}

.scroll4::-webkit-scrollbar-thumb:hover {
    border: 1.33px solid rgba(255, 196, 0, 1);
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);  
}

.scroll4::-webkit-scrollbar-track {
    border: 1px solid  #743801;;
    margin-bottom: 15px;
    border-radius: 2px;
}



.DropDownStats{
    overflow: auto;
    overflow-x: hidden;
    
}

.DropDownStats::-webkit-scrollbar {
    width: 4px; 
}

.DropDownStats::-webkit-scrollbar-thumb {
    border: 1.33px solid #6C11DE;
    background-color: #2E084A;
    border-radius: 3px;
}

.DropDownStats::-webkit-scrollbar-thumb:active {
    border: 1.33px solid #6C11DE; 
}

.DropDownStats::-webkit-scrollbar-thumb:hover {
    border: 1.33px solid #6C11DE;
    box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.2);  
}

.DropDownStats::-webkit-scrollbar-track {
    border: 1px solid rgba(128, 128, 128, 0.119);
    margin-bottom: 15px;
    border-radius: 2px;
}

