.input-custom::placeholder {
    color: rgba(255, 255, 255, 0.6);
}

.number-input {
    position: relative;
    display: inline-block;
  }
  
  .number-input input[type="number"] {
    padding-right: 25px; /* Espacio para las flechas personalizadas */
  }
  
  .number-input .arrow-up,
  .number-input .arrow-down {
    position: absolute;
    width: 20px;
    height: 20px;
    top: 50%;
    transform: translateY(-50%);
    background-color: #ccc;
    cursor: pointer;
  }
  
  .number-input .arrow-up {
    left: calc(100% - 20px);
  }
  
  .number-input .arrow-down {
    left: calc(100% - 20px);
    top: 50%;
    transform: translateY(-50%) rotate(180deg);
  }