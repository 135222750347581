#itemsRow::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 2px;
    background-color: #f5f5f5;
    border: 1px solid #FBCBE4;
    opacity: .2;
}
.force-overflow {
    height: 400px;
}
#itemsRow::-webkit-scrollbar {
    width: 9px;
    background-color: #f5f5f500;
}
#itemsRow::-webkit-scrollbar-thumb {
    border-radius: 2px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
    background-color: #E0E0E0;
    border: 1px solid #FBCBE4;
}
.scrollbar {
    padding-right: 30px;
    overflow-y: scroll;
    overflow-x: hidden;
}

#container {
    width:120%;
    border: none;
    box-shadow: none;
}

a:link {
    text-decoration: none;
}

